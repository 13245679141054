export default defineNuxtRouteMiddleware((to) => {
  const { isAuthenticated } = useUser()
  const login = useLogin()
  const localePath = useLocalePath()

  if (!isAuthenticated.value) {
    login.open({ success: () => navigateTo(to) }, "signup")

    return navigateTo(localePath({ name: "index" }))
  }
})
